<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">固件推送</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="单位">
					<a-select placeholder="请选择" v-model="query.hospital_id">
						<a-select-option :value="item.hospital_id" v-for="item in hospitals" :key="item.hospital_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input v-model="query.equipment_number" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item label="设备型号">
					<a-select placeholder="请选择设备型号" v-model="query.type">
						<a-select-option :value="1">
							Wifi设备
						</a-select-option>
						<a-select-option :value="2">
							4G设备
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="固件名称">
					<a-input v-model="query.firmware_name" placeholder="请输入固件包名称" />
				</a-form-model-item>
				<a-form-model-item label="版本号">
					<a-input v-model="query.firmware_version" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item label="推送状态">
					<a-select placeholder="请选择" v-model="query.push_status">
						<a-select-option :value="0">
							正在推送
						</a-select-option>
						<a-select-option :value="1">
							推送成功
						</a-select-option>
						<a-select-option :value="2">
							推送失败
						</a-select-option>
						<a-select-option :value="-1">
							其他
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
			<a-divider style="margin-left: -10px" />
			<a-row class="table-action-list" style="margin-top: 20px">
				<a-form-model-item label="选择固件">
					<a-select show-search :value="firmware_id" placeholder="可输入手机号查找" style="width: 250px"
						:default-active-first-option="false" :show-arrow="false" :filter-option="false"
						:not-found-content="null" @search="handleSearchCustomer" @change="handleChangeCustomer"
						@blur="blur">
						<a-select-option v-for="d in firmwares" :key="d.firmware_id">
							{{ d.name }}({{ d.version }})
						</a-select-option>
					</a-select>
					<a-button type="primary" @click="push" style="margin-left: 10px">推送</a-button>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
			:columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px" v-viewer>
			<span slot="type" slot-scope="text, record">
				<a-space>
					{{ record.type == 1 ? 'Wifi设备' : "4G设备" }}
				</a-space>
			</span>
			<span slot="push_status" slot-scope="text, record">
				<a-space v-if="record.push_status !== 0 && record.push_status !== 1 && record.push_status !== 2">
					<a>-</a>
				</a-space>
				<a-space v-if="record.push_status == 0">
					<a style="color: #e0852b">正在推送</a>
				</a-space>
				<a-space v-if="record.push_status == 1">
					<a style="color: #31e02b">推送成功</a>
				</a-space>
				<a-space v-if="record.push_status == 2">
					<a style="color: #E0352B">推送失败</a>
				</a-space>
			</span>
			<span slot="firmware_name" slot-scope="text, record">
				<a-space>
					{{ (record.firmware_name == "" || record.firmware_name == null) ? "-" : record.firmware_name }}
				</a-space>
			</span>
			<span slot="firmware_version" slot-scope="text, record">
				<a-space>
					{{ (record.firmware_version == "" || record.firmware_version == null) ? "-" : record.firmware_version }}
				</a-space>
			</span>
			<span slot="area" slot-scope="text, record">
				<a-space>
					{{ record.hospital_name + '-' + record.department_name }}
				</a-space>
			</span>
		</a-table>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			account_id: null,
			hospitals: [],
			hospitalselsect: [],
			departments: [],
			firmwares: [],
			firmware_id: null,
			pagetotal: 0,
			ks_visible: false,
			bindObj: {},
			visible: false,
			equipment: null,
			columns: [
				{
					title: '设备编号',
					dataIndex: 'equipment_number'
				},
				{
					title: '硬件编号',
					dataIndex: 'hardware_number'
				},
				{
					title: '设备型号',
					scopedSlots: {
						customRender: 'type'
					}
				},
				{
					title: '单位',
					scopedSlots: {
						customRender: 'area'
					}
				},
				{
					title: '固件包名称',
					scopedSlots: {
						customRender: 'firmware_name'
					}
				},
				{
					title: '版本号',
					scopedSlots: {
						customRender: 'firmware_version'
					}
				},

				{
					title: '推送状态',
					scopedSlots: {
						customRender: 'push_status'
					}
				},
				{
					title: '推送时间',
					dataIndex: 'updated_at_str'
				},
			],
			data: [],
			selectedRowKeys: [],
		}
	},
	created() {
		this.getList()
		this.getCustomerList('')
		this.getHospitals()
	},
	methods: {
		push() {
			console.log('我选中了啥', this.selectedRowKeys)
			if (this.selectedRowKeys.length <= 0) {
				this.$message.error('请选中设备')
				return;
			}
			if (this.firmware_id == null) {
				this.$message.error('请选择固件')
				return;
			}
			let obj = {
				equipment_ids: this.selectedRowKeys,
				firmware_id: this.firmware_id
			}
			this.$post('firmware/push', obj)
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.firmware_id = null
						this.selectedRowKeys = []
						this.getList()
						this.$message.success(msg, 4)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.confirmLoading = false
				})
		},
		handleSearchCustomer(value) {
			this.getCustomerList(value)
		},
		handleChangeCustomer(value) {
			console.log("change", value);
			this.firmware_id = value;
		},
		blur() {
			console.log("失去焦点")
			this.getCustomerList('')
		},
		getCustomerList(name) {
			this.$post(domain + '/admin/v1/firmware/listNoPage', { "name": name }).then(res => {
				if (res.code === 0) {
					this.firmwares = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		stop(id) {
			this.$post(domain + '/admin/v1/reconciliation/accountStopUsing', { 'account_id': id }).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		getHospitals() {
			this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(res => {
				if (res.code === 0) {
					this.hospitals = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		handleCancel() {
			this.visible = false;
			this.initForm()
		},
		add() {
			this.visible = true;
		},
		toEdit(item) {
			this.form = item;
			this.account_id = item.account_id;
			if (item.hospital_id !== null && item.hospital_id !== '') {
				this.getDepartments(item.hospital_id)
			}
			this.visible = true;
		},
		initForm() {
			this.form = {
				hospital_id: "",
				department_id: ""
			}
			this.departments = []
			console.log("清空后还有吗", this.form.hospital_id)
			this.account_id = null
		},
		hospitalChange(name, obj) {
			this.$post(domain + '/admin/v1/hospital/departmentList', {
				hospital_id: obj.data.key,
				limit: 10000,
				page: 1,
			}).then(res => {
				if (res.code === 0) {
					this.departments = res.data.list
					this.form.department_id = null
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		getDepartments(hospital_id) {
			this.$post(domain + '/admin/v1/hospital/departmentList', {
				hospital_id: hospital_id,
				limit: 10000,
				page: 1,
			}).then(res => {
				if (res.code === 0) {
					this.departments = res.data.list
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/firmware/equipmentFirmwareList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList();
			console.log(query)
		},
		changeCheck(value) {
			if (value.length === 1) {
				this.query.is_on = value[0]
			} else {
				this.query.is_on = null
			}
			this.getList()
		},
		onSelectChange(selectedRowKeys) {
			console.log('选中中', selectedRowKeys)
			this.selectedRowKeys = selectedRowKeys
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
